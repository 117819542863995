export function calcGain(prime, member, montant, p) {
  if (prime) {
    return parseFloat(
      ((montant * (p * 0.75) + 13 * member) * 0.855).toFixed(2)
    );
  }
  return parseFloat((montant * (p * 0.75) * 0.855).toFixed(2));
}

// Dépôt
export function calcDepot(gain, smax, smin) {
  if (gain > smax) {
    return parseFloat(gain - smax).toFixed(2);
  } else {
    if (gain < smin) {
      return parseFloat(gain - smin).toFixed(2);
    } else {
      return 0;
    }
  }
}

export function calcMonthDepot(data, p) {
  const result = parseFloat(
    sumOfData(
      data.value.map((w) => {
        const result = parseFloat(
          calcDepot(calcGain(!w.pde, w.member, w.montant, p), w.smax, w.smin)
        );
        return result;
      })
    )
  );
  localStorage.setItem("depot", result);
}

export function sumOfData(data) {
  const result = data.reduce((acc, curr) => {
    return acc + curr;
  }, 0);

  return parseFloat(result).toFixed(2);
}

export function calcPourcent(G, MAX) {
  const result = parseInt((G * 100) / (1.25 * MAX));
  if (result > 100) {
    return 100;
  }
  return result;
}

// Salary
export function srd(G, MIN, MAX, D) {
  if (G < MIN) {
    if (G + D > MIN) {
      if (G + D > MAX) {
        return MAX;
      } else {
        return parseFloat(G + D);
      }
    } else {
      return MIN;
    }
  } else if (G > MIN && G < MAX) {
    return G;
  } else if (G > MAX) {
    return MAX;
  }
}

// Palier
import { setBddPalier } from "@/functions/bdd_functions.js";

export function calcPalier(store) {
  const montant = sumOfData(store.state.weeks.map((w) => w.montant));

  if (montant >= 45000) {
    setBddPalier(store, 2);
  } else if (montant >= 30000) {
    setBddPalier(store, 1);
  } else {
    setBddPalier(store, 0);
  }
}
