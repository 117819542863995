export function getBddMonth(store) {
  const month = parseInt(localStorage.getItem("month"));

  if (!month && month !== 0) {
    const mm = parseInt(new Date().getMonth());
    store.dispatch("setMonth", mm);
    localStorage.setItem("month", mm);
    return mm;
  }
  store.dispatch("setMonth", month);
  return month;
}

export function setBddMonth(store, newMonth) {
  store.dispatch("setMonth", newMonth);
  localStorage.setItem("month", newMonth);
}
