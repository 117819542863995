const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

// Add into vuex
export async function addWeek(store, week, mode) {
  try {
    await store.dispatch("addWeek", week);
    if (mode === 1) {
      await addWeekLocal(week);
    }
  } catch (error) {
    console.log(error);
  }
}

// Add into WesserDB
async function addWeekLocal(week) {
  try {
    const request = indexedDB.open("wesserDB", 1);

    request.onerror = (err) => {
      console.log("Error with IndexedDB: ", err);
    };

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("weeks", "readwrite");
      const weekDB = transaction.objectStore("weeks");

      weekDB.put(week);

      transaction.oncomplete = () => {
        db.close();
      };
    };
  } catch (err) {
    console.log("Error with IndexedDB: ", err);
  }
}

// Remove into vuex
export async function removeWeek(store, week) {
  try {
    const index = store.state.weeks.findIndex((w) => w.id === week.id);

    await store.dispatch("removeWeek", index);
    await removeWeekLocal(week);
  } catch (error) {
    console.log(error);
  }
}

// Remove into WesserB
async function removeWeekLocal(weekToRemove) {
  try {
    const request = indexedDB.open("wesserDB", 1);

    request.onerror = (err) => {
      console.error("Error with IndexedDB: ", err);
    };

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction("weeks", "readwrite");
      const weeksDB = transaction.objectStore("weeks");

      weeksDB.delete(weekToRemove.id);

      transaction.oncomplete = () => {
        db.close();
      };
    };
  } catch (err) {
    console.error("Error with IndexedDB: ", err);
  }
}

// / Charge Vuex with WesserDB
export async function getWeeksLocal(store) {
  return new Promise((resolve, reject) => {
    try {
      const request = indexedDB.open("wesserDB", 1);

      request.onerror = (err) => {
        console.error("Error with IndexedDB: ", err);
        reject(err);
      };

      request.onsuccess = () => {
        const db = request.result;
        const transaction = db.transaction("weeks", "readonly");
        const weeksDB = transaction.objectStore("weeks");

        const requestAllData = weeksDB.getAll();

        requestAllData.onerror = (err) => {
          console.error("Error with IndexedDB: ", err);
          reject(err);
        };

        requestAllData.onsuccess = () => {
          const data = requestAllData.result;
          for (let week of data) {
            addWeek(store, week, 2);
          }
          resolve();
        };
      };
    } catch (err) {
      console.error("Error with IndexedDB: ", err);
      reject(err);
    }
  });
}

// Palier
export function getBddPalier(store) {
  const palier = parseFloat(localStorage.getItem("palier"));

  if (!palier) {
    const p = 0.6;
    store.dispatch("setPalier", p);
    localStorage.setItem("palier", p);
    return p;
  }
  store.dispatch("setPalier", palier);
  return palier;
}

export function setBddPalier(store, index) {
  let palier = 0.6;
  if (index === 0) {
    palier = 0.6;
  } else if (index === 1) {
    palier = 0.66;
  } else if (index === 2) {
    palier = 0.72;
  }
  store.dispatch("setPalier", palier);
  localStorage.setItem("palier", palier);
}

// Update V1.3
export async function updateHours(store) {
  const version = parseFloat(localStorage.getItem("version"));
  if (version !== 1.3) {
    const data = store.state.weeks;
    try {
      for (let i = 0; i < data.length; i++) {
        const d = data[i];
        let smin;
        if (d.pde) {
          smin = 700;
        } else {
          smin = 875;
        }
        const playload = {
          id: Date.now(),
          month: d.month,
          week: d.week,
          member: d.member,
          montant: d.montant,
          pde: d.pde,
          hours: 35,
          smin: smin,
          smax: parseInt(smin * 2),
        };
        await addWeek(store, playload, 1);
        await removeWeek(store, d);
      }
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.setItem("version", 1.3);
    }
  }
}
