<template>
  <v-app>
    <v-main id="main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup>
import "@mdi/font/css/materialdesignicons.css";
// Import vue fonctions
import { onMounted } from "vue";
// Import store
import { useStore } from "vuex";
const store = useStore();
// Import js functions
import { getWeeksLocal, getBddPalier, updateHours } from "@/functions/bdd_functions";
import { getBddMonth } from "@/functions/time_functions";
import { calcPalier } from "@/functions/math_functions";

const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

function initIndexedDB() {
  try {
    const request = indexedDB.open("wesserDB");

    request.onerror = (err) => {
      console.error("Error with IndexedDB: ", err);
    };

    request.onupgradeneeded = () => {
      const db = request.result;
      const weekTable = db.createObjectStore("weeks", { keyPath: "id" });
      weekTable.createIndex("weeks", ["weeks"], { unique: false });
    };
  } catch (err) {
    console.error("Error with IndexedDB: ", err);
  }
}

onMounted(async () => {
  try {
    initIndexedDB();
    await getWeeksLocal(store);
    getBddMonth(store);
    getBddPalier(store);
    calcPalier(store);
    await updateHours(store);
  } catch (error) {
    console.log(error);
  }
});
</script>

<style>
@import url("@/assets/base.css");
#main {
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}
</style>
