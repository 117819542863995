<template>
  <div class="recap-infos">
    <div>
      <h3>
        Gain
        <h6 class="ml-2">CHF</h6>
      </h3>
      <v-divider></v-divider>
      <h1>{{ monthGain }}</h1>
      <v-divider></v-divider>
    </div>
    <v-divider vertical></v-divider>
    <div>
      <h3>
        Salaire
        <h6 class="ml-2">CHF</h6>
      </h3>
      <v-divider></v-divider>
      <h1>{{ salary }}</h1>
      <v-divider></v-divider>
    </div>
  </div>
  <div class="gauge-container">
    <h4 class="price-label cursor-title-min">
      {{ monthSMIN }}
      <h6 class="ml-2">CHF</h6>
    </h4>
    <div class="cursor-min"></div>
    <h5 class="cursor-label-min">Min</h5>
    <h5 class="price-label label-depot-gauge-minus" v-if="realDepot < 0">
      {{ realDepot }}
    </h5>
    <h4 class="price-label cursor-title-max">
      {{ monthSMAX }}
      <h6 class="ml-2">CHF</h6>
    </h4>
    <div class="cursor-max"></div>
    <h5 class="cursor-label-max">Max</h5>
    <h5 class="price-label label-depot-gauge-plus" v-if="realDepot > 0">
      + {{ realDepot }}
    </h5>
    <div class="gauge"></div>
  </div>
</template>

<script setup>
// Import vue fonctions
import { ref, computed, watch } from "vue";
// Import js functions
import {
  sumOfData,
  calcGain,
  srd,
  calcMonthDepot,
} from "@/functions/math_functions";
// Import store
import { useStore } from "vuex";
const store = useStore();
const month = computed(() => store.state.month);
const data = computed(() =>
  store.state.weeks.filter((w) => w.month === month.value)
);
// Palier
const palier = computed(() => store.state.palier);
// Gain
const gains = computed(() =>
  data.value.map((w) => calcGain(!w.pde, w.member, w.montant, palier.value))
);
const monthGain = computed(() => Math.round(sumOfData(gains.value)));

// Depot
const realDepot = ref(0);

function calcRealDepot() {
  const depot = parseInt(localStorage.getItem("depot"));
  if (depot > 0 && salary.value === monthSMAX.value) {
    realDepot.value = Math.round(monthGain.value - monthSMAX.value);
  } else if (depot < 0) {
    realDepot.value = Math.round(depot);
  } else {
    realDepot.value = 0;
  }
}

// Salary
const monthSMAX = computed(() => {
  return parseFloat(sumOfData(data.value.map((w) => w.smax)));
});

const monthSMIN = computed(() => {
  return parseFloat(sumOfData(data.value.map((w) => w.smin)));
});

const salary = computed(() => {
  const depot = parseFloat(localStorage.getItem("depot"));
  const result = Math.round(
    srd(monthGain.value, monthSMIN.value, monthSMAX.value, depot)
  );
  if (result) {
    return result;
  }
  return 0;
});

// Gauge
const progress = computed(() => {
  const MAX = monthSMAX.value;
  const G = monthGain.value;
  if (G <= 0) {
    return "0%";
  }
  const result = parseFloat((G * 100) / (1.25 * MAX));
  if (result > 100) {
    return "100%";
  }
  return `${result}%`;
});

const gaugeColor = computed(() => {
  const value = parseInt(String(progress.value).split("%")[0]);
  if (value <= 40) {
    // YELLOW
    return "#fff176";
  } else if (value > 40 && value <= 80) {
    // GREEN
    return "#a5d6a7";
  }
  return "#ea80fc";
});

watch([() => month.value, () => data.value], () => {
  calcMonthDepot(data, palier.value);
  calcRealDepot();
});
</script>

<style>
.recap-infos {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
}
.recap-infos div {
  display: block;
  width: 100%;
  text-align: center;
}

.gauge-container {
  position: relative;
  margin-top: 5rem;
  width: 100%;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 0.5rem;
  margin-bottom: 4rem;
  box-shadow: 0px 0px 30px -10px #3a2d287f;
}
.cursor-min {
  position: absolute;
  left: 40%;
  width: 0.1rem;
  height: 40px;
  background-color: black;
}
.cursor-title-min {
  position: absolute;
  top: -30px;
  left: 38%;
}
.cursor-label-min {
  position: absolute;
  bottom: -25px;
  left: 37%;
}

.gauge {
  width: v-bind(progress);
  height: 100%;
  background-color: v-bind(gaugeColor);
  transition: width 0.3s ease;
  border-radius: 0.5rem;
}
.cursor-max {
  position: absolute;
  left: 80%;
  width: 0.1rem;
  height: 40px;
  background-color: black;
}
.cursor-title-max {
  position: absolute;
  top: -30px;
  left: 78%;
}
.cursor-label-max {
  position: absolute;
  bottom: -25px;
  left: 77%;
}

@media (min-width: 550px) {
  .cursor-label-min {
    left: 40%;
  }
  .cursor-label-max {
    left: 80%;
  }
}

.label-depot-gauge-plus {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(0, -50%);
}

.label-depot-gauge-minus {
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(0, -50%);
}
</style>
