import { createStore } from "vuex";

export default createStore({
  state: {
    weeks: [],
    month: "",
    palier: 0,
  },
  getters: {},
  mutations: {
    // Week

    addWeek(state, week) {
      state.weeks.push(week);
    },
    removeWeek(state, index) {
      state.weeks.splice(index, 1);
    },
    // Month
    setMonth(state, month) {
      state.month = month;
    },

    // Palier
    setPalier(state, palier) {
      state.palier = palier;
    },
  },
  actions: {
    // Week
    addWeek({ commit }, week) {
      commit("addWeek", week);
    },
    removeWeek({ commit }, index) {
      commit("removeWeek", index);
    },

    // Month
    setMonth({ commit }, month) {
      commit("setMonth", month);
    },

    // Palier
    setPalier({ commit }, palier) {
      commit("setPalier", palier);
    },
  },
  modules: {},
});
