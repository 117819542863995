<template>
  <div class="palier-container">
    <h3>Palier</h3>
    <div class="palier-sub-container">
      <v-slider
        v-model="palierIndex"
        show-ticks="always"
        step="1"
        max="2"
        tick-size="2"
        :messages="palierMsg"
        thumb-label
        thumb-color="#455A64"
        track-color="#90A4AE"
        :track-fill-color="palierColor"
        @click="setPalier()"
      ></v-slider>
    </div>
  </div>
</template>

<script setup>
// Import vue fonctions
import { ref, computed } from "vue";
// Import store
import { useStore } from "vuex";
const store = useStore();
// Import js functions
import { setBddPalier } from "@/functions/bdd_functions.js";
import { sumOfData } from "@/functions/math_functions";

// Palier
const palier = computed(() => store.state.palier);
function calcRealMontant() {
  return parseInt(sumOfData(store.state.weeks.map((w) => w.montant)));
}
function calcPalierInfos() {
  if (palier.value === 0.66) {
    return {
      index: 1,
      msg: "30000 CHF",
      color: "#a5d6a7",
    };
  } else if (palier.value === 0.72) {
    return {
      index: 2,
      msg: "45000 CHF",
      color: "#ea80fc",
    };
  }
  return {
    index: 0,
    msg: `${calcRealMontant() < 30000 ? calcRealMontant() : "< 30000"} CHF`,
    color: "#fff176",
  };
}

const palierIndex = ref(calcPalierInfos().index);
const palierMsg = computed(() => calcPalierInfos().msg);
const palierColor = computed(() => calcPalierInfos().color);

function setPalier() {
  const p = palierIndex.value;
  setBddPalier(store, p);
}
</script>

<style>
.palier-container {
  display: flex;
  flex-direction: row;
}

.palier-sub-container {
  display: flex;
  justify-content: left;
  width: 100px;
  margin-left: 0.6rem;
}
</style>
