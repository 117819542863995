<template>
  <div
    @touchstart="
      () => {
        store.dispatch('setLockSwipe', true);
      }
    "
    @touchend="
      () => {
        store.dispatch('setLockSwipe', false);
      }
    "
    class="table-container"
  >
    <v-data-table
      class="custom-headers"
      density="compact"
      :loading="loader"
      :headers="headers"
      :items="items"
    >
      <template v-slot:[`item.gauge`]="{ item }">
        <week_gauge :pourcent="item.pourcent"></week_gauge>
      </template>
      <template v-slot:[`item.pde`]="{ item }">
        <div :class="!item.pde ? 'pdeTrue' : 'pdeFalse'"></div>
      </template>

      <template v-slot:[`item.remove`]="{ item }">
        <v-btn
          color="red"
          variant="text"
          icon="mdi-close"
          rounded="sm"
          size="2rem"
          @click="removeMode(item)"
        ></v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script setup>
// Import vue fonctions
import { ref, computed } from "vue";
// Import store
import { useStore } from "vuex";
const store = useStore();
const month = computed(() => store.state.month);
const data = computed(() =>
  store.state.weeks.filter((w) => w.month === month.value)
);
// Import js functions
import { calcGain, calcDepot, calcPourcent } from "@/functions/math_functions";
import { removeWeek } from "@/functions/bdd_functions";
// Import component
import week_gauge from "@/components/week_gauge.vue";

const headers = [
  {
    align: "start",
    key: "week",
    title: "S",
  },
  { key: "member", title: "Membres" },
  { key: "montant", title: "Montant" },
  { key: "gain", title: "Gain" },
  { key: "gauge" },
  { key: "depot", title: "Dépôt" },
  { key: "pde", title: "Collab" },
  { key: "remove" },
];

// Palier
const palier = computed(() => store.state.palier);

const items = computed(() => {
  let result = [];
  for (let w of data.value) {
    const gain = calcGain(!w.pde, w.member, w.montant, palier.value);
    const obj = {
      id: w.id,
      week: w.week,
      member: w.member,
      montant: w.montant,
      pde: w.pde,
      gain: String(gain).replace(".", ","),
      depot: String(calcDepot(gain, w.smax, w.smin)).replace(".", ","),
      pourcent: calcPourcent(gain, w.smax),
    };
    result.push(obj);
  }

  return result.sort((a, b) =>
    a.week > b.week ? 1 : b.week > a.week ? -1 : 0
  );
});

// Remove week
const loader = ref(false);
function removeMode(item) {
  loader.value = true;
  setTimeout(() => {
    removeWeek(store, item);
    loader.value = false;
  }, 800);
}
</script>

<style>
.table-container {
  width: 100%;
  margin-top: 2rem;
}

.v-table .v-data-table-footer {
  display: none;
}
.v-table .v-table__wrapper {
  background-color: var(--bg-color);
}
.custom-headers .v-data-table-header__content {
  font-weight: 700;
}

.pdeTrue {
  background-color: rgba(12, 171, 12, 0.47);
  border: solid 0.1rem rgba(9, 98, 9, 0.47);
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}
.pdeFalse {
  background-color: rgba(60, 60, 60, 0.132);
  border: solid 0.1rem gray;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}
</style>
