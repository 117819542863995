<template>
  <div class="month-header">
    <v-btn
      icon="mdi-chevron-left"
      rounded="sm"
      variant="text"
      @click="beforeMonth()"
    ></v-btn>
    <h1>{{ month_list[month] }}</h1>
    <v-btn
      icon="mdi-chevron-right"
      rounded="sm"
      variant="text"
      @click="afterMonth()"
    ></v-btn>
  </div>
</template>

<script setup>
// Import vue fonctions
import { computed } from "vue";
// Import js functions
import { setBddMonth } from "@/functions/time_functions";
// Import store
import { useStore } from "vuex";
const store = useStore();

const month = computed(() => store.state.month);

const month_list = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

function beforeMonth() {
  let newMonth = month.value;
  if (month.value > 0) {
    newMonth = month.value - 1;
  }
  setBddMonth(store, newMonth);
}
function afterMonth() {
  let newMonth = month.value;
  if (month.value < 11) {
    newMonth = month.value + 1;
  }
  setBddMonth(store, newMonth);
}
</script>

<style>
.month-header {
  display: flex;
  width: 80%;
  justify-content: space-between;
}
</style>
