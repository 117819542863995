<template>
  <month_header></month_header>
  <div class="inter-space"><v-divider class="my-2"></v-divider></div>
  <month_recap></month_recap>
  <div class="inter-space"><v-divider class="my-4"></v-divider></div>
  <week_table></week_table>
  <v-btn
    class="my-10"
    icon="mdi-plus"
    variant="tonal"
    style="border: solid 1px green"
    color="green"
    width="50%"
    rounded="lg"
    @click="dialog = true"
  ></v-btn>

  <div class="overlay" v-if="dialog"></div>
  <v-dialog v-model="dialog">
    <inputForm @done="dialog = false"></inputForm>
  </v-dialog>

  <div class="home-footer">
    <v-divider class="mb-2"></v-divider>
    <div>
      <p>V1.6</p>
      <p>Fox_Prog</p>
    </div>
  </div>
</template>

<script setup>
// Import vue fonctions
import { ref } from "vue";

// Import components
import month_header from "@/components/month_header.vue";
import month_recap from "@/components/month_recap.vue";
import week_table from "@/components/week_table.vue";
import inputForm from "@/components/input_form.vue";

const dialog = ref(false);
</script>

<style>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
}

.inter-space {
  width: 100%;
}

.home-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
}

.home-footer div {
  display: flex;
  justify-content: space-between;
  padding: 0 5px 5px 5px;
}

.home-footer div p {
  font-size: 0.6rem;
}
</style>
