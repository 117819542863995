<template>
  <div class="card">
    <div class="card-form">
      <v-form v-model="form">
        <v-select
          v-model="week"
          class="text-field mb-2"
          variant="outlined"
          density="compact"
          label="Semaine"
          width="210px"
          prepend-icon="mdi-calendar-range"
          :rules="[required]"
          :items="weeks"
        ></v-select>
        <v-text-field
          v-model="member"
          class="text-field mb-2"
          variant="outlined"
          type="number"
          label="Membres"
          width="210px"
          prepend-icon="mdi-account-group"
          hide-spin-buttons
          density="compact"
          :rules="[required, isPositive]"
        ></v-text-field>
        <v-text-field
          v-model="montant"
          class="text-field mb-2"
          variant="outlined"
          type="number"
          label="Montant"
          width="210px"
          prepend-icon="mdi-cash-multiple"
          hide-spin-buttons
          density="compact"
          :rules="[required, isPositive]"
        ></v-text-field>
        <v-text-field
          v-model="hours"
          class="text-field mb-2"
          variant="outlined"
          type="number"
          label="Heures"
          width="210px"
          prepend-icon="mdi-clock-outline"
          hide-spin-buttons
          density="compact"
          :rules="[required, isPositive]"
        ></v-text-field>
      </v-form>
      <v-divider vertical class="mx-4"></v-divider>
      <div class="form-options">
        <v-switch
          v-model="pde"
          color="green"
          label="Période d'essai"
        ></v-switch>
        <palier_selector></palier_selector>
      </div>
    </div>
    <div class="botton-action">
      <v-btn
        class="mt-3"
        :disabled="!form"
        variant="flat"
        width="49%"
        color="#46A758"
        @click="validForm()"
        >Valider</v-btn
      >
      <v-btn
        class="mt-3"
        variant="flat"
        width="49%"
        color="#E5484D"
        @click="emit('done')"
        >Annuler</v-btn
      >
    </div>
  </div>
</template>

<script setup>
// Import vue fonctions
import { ref, defineEmits, computed } from "vue";
const emit = defineEmits(["done"]);
// Import store
import { useStore } from "vuex";
const store = useStore();
// Import components
import palier_selector from "@/components/palier_selector.vue";
// Import js functions
import { addWeek } from "@/functions/bdd_functions";
import { calcPalier } from "@/functions/math_functions";

// Variables
const form = ref(false);
const weeks = computed(() => {
  let result = [];
  for (let w = 1; w <= 51; w++) {
    result.push(w);
  }
  return result;
});
const week = ref(null);
const member = ref(null);
const montant = ref(null);
const pde = ref(false);
const hours = ref(35);

// Palier
// const palier = computed(() => store.state.palier);

const monthOfWeek = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 1,
  7: 1,
  8: 1,
  9: 1,
  10: 2,
  11: 2,
  12: 2,
  13: 2,
  14: 3,
  15: 3,
  16: 3,
  17: 3,
  18: 4,
  19: 4,
  20: 4,
  21: 4,
  22: 4,
  23: 5,
  24: 5,
  25: 5,
  26: 5,
  27: 6,
  28: 6,
  29: 6,
  30: 6,
  31: 6,
  32: 7,
  33: 7,
  34: 7,
  35: 7,
  36: 8,
  37: 8,
  38: 8,
  39: 8,
  40: 9,
  41: 9,
  42: 9,
  43: 9,
  44: 9,
  45: 10,
  46: 10,
  47: 10,
  48: 10,
  49: 11,
  50: 11,
  51: 11,
};

// Rules
function required(v) {
  return !!v || "Required";
}

function isPositive(v) {
  return v > 0 ? true : "Negative";
}

// Valid form
async function validForm() {
  const smin = pde.value
    ? parseInt(hours.value * 20)
    : parseInt(hours.value * 25);
  const data = {
    id: Date.now(),
    month: monthOfWeek[week.value],
    week: parseInt(week.value),
    member: parseInt(member.value),
    montant: parseInt(montant.value),
    pde: pde.value,
    hours: hours.value,
    smin: smin,
    smax: parseInt(smin * 2),
  };
  try {
    await addWeek(store, data, 1);
    calcPalier(store);
  } catch (error) {
    console.log(error);
  } finally {
    emit("done");
  }
}
</script>

<style>
.card {
  background-color: var(--bg-color);
  padding: 1rem;
  border-radius: 0.2rem;
  width: fit-content;
  box-shadow: 0px 0px 30px -10px #3a2d287f;
  align-self: center;
}
.card-form {
  display: flex;
}

.botton-action {
  display: flex;
  width: 100%;
  justify-content: left;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media (max-width: 550px) {
  .card-form {
    flex-direction: column;
    align-items: left;
  }
}
</style>
