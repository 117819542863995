<template>
  <div class="week-gauge-container">
    <div class="week-gauge-yellow"></div>
    <div class="week-gauge-green"></div>
    <div class="week-gauge-purple"></div>
    <div class="week-gauge-cursor"></div>
  </div>
</template>

<script setup>
// Import vue fonctions
import { ref, defineProps } from "vue";
const props = defineProps(["pourcent"]);
const progress = ref(`${props.pourcent}%`);
</script>

<style>
.week-gauge-container {
  position: relative;
  display: flex;
  width: 150px;
  height: 0.3rem;
  box-shadow: 0px 0px 12px 0px #9a9999ab;
}
.week-gauge-yellow {
  width: 40%;
  height: 100%;
  background-color: var(--color-1);
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
}
.week-gauge-green {
  width: 40%;
  height: 100%;
  background-color: var(--color-2);
}
.week-gauge-purple {
  width: 20%;
  height: 100%;
  background-color: var(--color-3);
  border-end-end-radius: 5px;
  border-start-end-radius: 5px;
}

.week-gauge-cursor {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: v-bind(progress);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(77, 75, 75);
}
</style>
